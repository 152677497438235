<template>
  <div class="scrap-wrap">
    <Split :offset.sync="split.offset" :height="splitHeight">
      <template slot="left">
        <EquipmentClass ref="equipmentClass" @nodeClick="setEquipmentClassId" @refrash="refrash" />
      </template>
      <template slot="right">
        <div class="top">
          <SearchForm ref="searchForm" :form-list-extend="searchFormList" :form-data="searchFormData" @search="searchTable" />
        </div>
        <div class="bottom">
          <FunctionHeader
            ref="functionHeader"
            v-model="functionHeader.searchVal"
            search-title="请输入设备编码/名称"
            :tags="!selectionData.length ? functionHeader.tags :[]"
            :export-name="functionHeader.exportName"
            :export-params="functionHeader.exportParams"
            :import-name="!selectionData.length ? functionHeader.importName :''"
            :show-export="!selectionData.length ? true : false"
            @search="$refs.mTable.setTableData(true)"
          >
            <div slot="all" v-show="selectionData.length">
            <el-button v-if="permission('Export')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
            <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
              已选
              <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
              / {{ total }}项
            </span>
              <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
            </div>
          </FunctionHeader>
          <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <el-button slot="code" slot-scope="{ row }" style="white-space: pre-wrap;" type="text" @click="viewDetail(row)">{{ row.code }}</el-button>
            <div slot="equipmentClassType" slot-scope="{ row }" type="text">{{ row.equipmentClassType | equipmentClassTypeName }}</div>
            <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
              <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
              <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
            </div>
            <div slot="drawing" slot-scope="{ row }">
              <el-image
                v-if="row.drawing"
                :src="row.drawing"
                class="drawing-img"
                :preview-src-list="fixImg(row.drawing)"
              />
              <!-- <img :src="row.drawing" class="drawing-img" @click="handlePictureCardPreview(row.drawing)" v-if="row.drawing"/> -->
            </div>
            <div slot="status" slot-scope="{ row }" class="is-enabled">
              <div :style="{background: statusColor[row.status] }" />
              <div>{{ row.status | statusName }}</div>
            </div>
            <div slot="action" slot-scope="{ row }">
              <!-- <template v-if="['kiki'].includes(memberCode)">
                <el-button v-if="permission('AddRepair')" type="text" @click="addEquipmentRepair(row)">新建维保任务</el-button>
                <el-divider v-if="permission('AddRepair')" direction="vertical" />
              </template> -->
              <template>
                <el-button v-if="permission('PrintCode')" type="text" @click="printLabel(row)">打印设备条码</el-button>
                <el-divider v-if="permission('PrintCode')" direction="vertical" />
              </template>
              <el-dropdown @command="(command) => { more(row, command) }">
                <span class="el-dropdown-link">
                  更多<i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
                  <el-dropdown-item v-if="permission('Status')" command="switchStatus">{{ row.isEnabled ? '停用' : '启用' }}</el-dropdown-item>
                  <el-dropdown-item v-if="permission('Copy')" command="copy">复制</el-dropdown-item>
                  <el-dropdown-item v-if="permission('Modify')" command="modify">修改</el-dropdown-item>
                  <el-dropdown-item v-if="permission('Del')" command="del">删除</el-dropdown-item>
                  <el-dropdown-item v-if="['kiki'].includes(memberCode) && permission('PrintCode')" command="print">打印设备条码</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </MTable>

          <MDialog v-model="visible" :title="messages[currentType]" @onOk="submit">
            <MFormBuilder ref="formBuild" :form-data="formData" :form-list="currentType === 'addRepair' ? formList.addRepair : formList.add">
              <div slot="equipmentClassId">
                <el-popover
                  v-model="inputVisible"
                  placement="bottom"
                  width="300"
                  trigger="click"
                  popper-class="type-popover"
                >
                  <div>
                    <div v-if="formOptions.equipmentClassList.length > 0" class="content-container">
                      <div
                        v-for="item in formOptions.equipmentClassList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        class="selectType"
                      >
                        <span class="name-tit" @click="selectName(item, 'change')">{{ item.name }}</span>
                      </div>
                    </div>
                    <div v-else class="nodata">
                      暂无数据，请新增
                    </div>
                    <div class="select-add" @click="addType">
                      <span class="el-icon-circle-plus-outline icons" />
                      <span class="tit">新增</span>
                    </div>
                  </div>
                  <div slot="reference" class="com-ipts">
                    {{ addNames ? addNames : '请选择' }}
                    <span class="el-icon-arrow-down icons" />
                  </div>
                </el-popover>
              </div>
              <div slot="drawing">
                <el-upload
                  class="avatar-uploader"
                  v-bind="options"
                  accept=".png,.jpg"
                  :before-upload="beforeAvatarUpload"
                  :on-success="handleAvatarSuccess"
                >
                  <img v-if="equipmentImageUrl" :src="equipmentImageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon" />
                </el-upload>
              </div>
            </MFormBuilder>
          </MDialog>
          <MDialog v-model="visibleEquipment" title="新增设备类" @onOk="submitFormEquipment">
            <MFormBuilder ref="formBuilds" :form-data="formDatas" :form-list="formLists" />
          </MDialog>
          <print-code ref="printCode" />
          <el-dialog v-if="dialogVisible" :show-close="false">
            <img :src="dialogImageUrl" alt="">
          </el-dialog>
        </div>
      </template>
    </Split>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import { searchFormList, formList, repairFormList } from './form-list'
import { columns, getFunctionList } from './columns'
import { getUUid, setTreeData } from '@/utils'
import equipmentClassApi from '@/api/information/equipment/equipment-class'
import api from '@/api/equipment/equipment'
import apis from '@/api/information/equipment/equipment-class'
import userApi from '@/api/sets/user/user'
import shiftApi from '@/api/sets/shift'
import { Encrypt } from '@/utils/sercet'
import conventionMixin from '@/mixin/conventional-page'
import PrintCode from '@/components/PrintCode/printPackage'
import dayjs from 'dayjs'
import { equipmentType, equipmentStatus, status } from '@/config/options.config'
import Split from '@/components/Split/Split'
import EquipmentClass from '@/components/EquipmentClass/EquipmentClass'
import stationApi from '@/api/information/production/station'
import keApi from '@/api/information/unit'
import fApi from '@/api/sets/framework'
import { getToken } from '@/utils/auth'

export default {
  name: 'EquipmentAccountList',
  components: { PrintCode, SearchForm, FunctionHeader, Split, EquipmentClass },
  filters: {
    equipmentClassTypeName(id) {
      return equipmentType.find(item => item.id === id).name
    },
    statusName(id) {
      const omp = equipmentStatus.find(item => item.id === id)
      if(omp){
        return omp.name
      }else {
        return '-'
      }
      // return equipmentStatus.find(item => item.id === id).name
    }
  },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      visibleEquipment: false,
      formDatas: {},
      formLists: [
        {
          label: '设备类名称',
          key: 'name',
          tagName: 'el-input',
          required: true
        },
        {
          label: '上级分类',
          key: 'parentId',
          tagName: 'el-select',
          required: true,
          props: {
            clearable: true
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              }
            }
          ]
        },
        {
          label: '设备属性',
          key: 'type',
          tagName: 'el-select',
          required: true,
          props: {
            clearable: true
          },
          defaultValue: 0,
          children: [
            {
              tagName: 'el-option',
              options: equipmentType,
              props: {
                label: 'name',
                value: 'id'
              }
            }
          ]
        },
        {
          label: '设备类状态',
          key: 'isEnabled',
          tagName: 'el-select',
          required: true,
          props: {
            clearable: true
          },
          defaultValue: 1,
          children: [
            {
              tagName: 'el-option',
              options: status,
              props: {
                label: 'name',
                value: 'id'
              }
            }
          ]
        },
        {
          label: '备注',
          key: 'remark',
          tagName: 'el-input',
          props: {
            type: 'textarea'
          }
        }
      ],
      inputVisible: false,
      params: {
        radio: 'Equipment'
      },
      columns,
      searchFormList,
      searchFormData: {},
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.addStation
            }
          }
        ],
        exportName: 'exportAccount',
        importName: 'account',
        exportParams: {}
      },
      formList: {
        add: formList,
        addRepair: repairFormList
      },
      formData: {},
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      currentType: 'add',
      visible: false,
      formOptions: {
        equipmentClassList: [],
        shiftOptions: [],
        userOptions: [],
        stationList: [],
        organizationList: []
      },
      statusColor: ['#FFCC78', '#24CAD7', '#E02020'],
      messages: {
        add: '新增',
        modify: '修改',
        copy: '复制',
        addRepair: '新建维保任务'
      },
      searchKeyword: true,
      split: {
        offset: 15
      },
      getTableDataParams: {
        equipmentClassId: ''
      },
      stationSearch: [{
        id: getUUid(),
        fieldName: 'equipmentId',
        fieldType: 'number',
        fieldValue: 0,
        operator: 'eq'
      }],
      options: {
        action: '',
        headers: { token: getToken() },
        showFileList: false
      },
      equipmentImageUrl: '',
      editId: undefined,
      dialogVisible: false,
      dialogImageUrl: '',
      selectionData: [],
      exportSelectionIds: [],
      classList: [{
        id: -1,
        name: ' '
      }],
      addNames: '',
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 36
    },
    splitHeight() {
      return this.$store.state.app.contentHeight - 235 + 83
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  beforeMount() {
    getFunctionList({
      equipmentClassChange: this.equipmentClassChange,
      stationChange: this.stationChange,
      serviceChange: this.serviceChange
    })
  },
  mounted() {
    this.getEquipmentClassList()
    this.getUserList()
    this.getUser()
    this.getShiftList()
    this.getStationList()
    this.getStationLists()
    this.getOrganizationList()
    const currentUrl = window.location.origin
    if (currentUrl == 'http://58.246.65.68:8201' || currentUrl == 'http://mes.bbelc.cn:8201') {
      this.options.action = `${process.env.VUE_APP_SCENCE_API}api/web/v1/basicData/private/file/upload`
    } else {
      this.options.action = `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`
    }
  },
  methods: {
    submitFormEquipment(callback) {
      const { form } = this.$refs.formBuilds
      this.$refs.formBuilds.formRefs().validate(async(valid) => {
        if (valid) {
          const type = 'add'
          const res = await apis[`${type}EquipmentClass`](
            this.createSetDatas(form)
          )
          if (res) {
            this.$message.success('新增成功')
            this.visibleEquipment = false
            this.addNames = form.name
            this.getEquipmentClassList(1)
            this.$refs.equipmentClass.getEquipmentClassList()
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    createSetDatas(raw) {
      return {
        name: raw.name,
        parentId: raw.parentId || -1,
        type: raw.type,
        isEnabled: raw.isEnabled,
        remark: raw.remark
      }
    },
    addType() {
      this.formLists[1].children[0].options = this.classList
      this.visibleEquipment = true
    },
    selectName(row) {
      const current = this.formOptions.equipmentClassList.find(item => item.id === row.id)
      this.$refs.formBuild.setForm({
        equipmentClassId: row.id,
        equipmentClassType: current.type
      })
      this.addNames = row.name
      this.inputVisible = false
    },
    selectionChange(data) {
      this.selectionData = data
      this.exportSelectionIds = data.map(item => item.id)
    },
    resetSelectData() {
      this.selectionData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionData = this.selectionData.filter((item) => item.selectable)
    },
    allMore(command) {
      const functions = {
        batchExport: () => { this.batchExport() }
      }
      functions[command]()
    },
    refrash() {
      this.$refs.mTable.setTableData(true)
    },
    fixImg(img) {
      return [img]
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 20
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    handleAvatarSuccess(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.$refs.formBuild.setForm({
          drawing: res.result
        })

        this.equipmentImageUrl = res.result
      } else {
        this.$message.error(res.result)
      }
    },
    handlePictureCardPreview(row) {
      this.dialogImageUrl = row
      this.dialogVisible = true
    },
    setEquipmentClassId(val) {
      this.getTableDataParams.equipmentClassId = val
      this.$refs.mTable.setTableData(true)
    },
    async getEquipmentClassList(val) {
      const res = await equipmentClassApi.getEquipmentClassList()
      if (res) {
        const data = res && res.length > 0 ? res.filter(item => item.isEnabled) : []
        this.formOptions.equipmentClassList = data
        this.formList.add[2].children[0].options = data
        this.classList = [...this.classList, ...data]
        if (+val === 1) {
          const row = data && data.length > 0 ? data.filter(item => item.name === this.addNames)[0] : ''
          this.$refs.formBuild.setForm({
            equipmentClassId: row.id,
            equipmentClassType: row.type
          })
        }
      }
    },
    async getUserList() {
      const res = await userApi.getUserList(this.defaultSearch)
      if (res) {
        this.formList.addRepair[3].children[0].options = res
        this.formList.add[14].children[0].options = res
        this.formOptions.userOptions = res
      }
    },
    // 获取客户
    async getUser() {
      const res = await keApi.getCustomerList({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.formList.add.forEach(p => {
          if(p.index === 16){
            p.children[0].options = res;
          }
        })
      }
    },
    async getStationList() {
      const res = await stationApi.getStationList()
      if (res) {
        this.searchFormList[0].children[0].options = res
      }
    },
    // 获取上级组织
    async getOrganizationList() {
      const res = await fApi.getOrganizationList()
      if (res) {
        this.formOptions.organizationList = res
        this.formList.add[13].props.options = setTreeData(res)
      }
    },
    // 筛选工位
    async getStationLists() {
      const serach = [
        {
          id: getUUid(),
          fieldName: 'equipmentId',
          fieldType: 'number',
          fieldValue: `0^${this.editId}`,
          operator: 'in'
        }
      ]
      const data = this.currentType === 'add' ? this.stationSearch : serach
      const res = await stationApi.getStationList({
        searchVal: Encrypt(JSON.stringify(data))
      })
      if (res) {
        // this.formOptions.stationList = res
        this.formList.add[5].children[0].options = res
      }
    },
    stationChange(val) {
      // console.log('ceshi=>', this.formOptions.stationList)
    },
    serviceChange(val) {
      // console.log('ceshi=>', this.formOptions.stationList)
    },
    async addStation() {
      this.equipmentImageUrl = ''
      this.formData = {}
      this.currentType = 'add'
      this.addNames = ''
      await this.getStationLists()
      await this.getEquipmentClassList()
      // 选中左边设备类，新增设备自动匹配设备类和属性
      if (this.getTableDataParams.equipmentClassId) {
        const current = this.formOptions.equipmentClassList.find(item => item.id === +this.getTableDataParams.equipmentClassId)
        this.formData = {
          equipmentClassId: this.getTableDataParams.equipmentClassId,
          equipmentClassType: current.type
        }
      }
      this.visible = true
      await this.createCode()
    },
    async modify(row, commond) {
      this.currentType = commond
      this.editId = row.id
      await this.getStationLists()
      this.formData = this._.cloneDeep(row)
      this.formData.organizationId = (row.organizationIdList || "").split("^").map(item => +item);
      this.formData.stationId = row.stationId === 0 ? '' : row.stationId
      this.formData.customerId = row.customerId === 0 ? '' : row.customerId
      this.equipmentImageUrl = row.drawing
      this.addNames = row.equipmentClassName
      this.visible = true
      if (commond === 'copy') {
        await this.createCode()
      }
    },
    async createCode() {
      if (sessionStorage.getItem('memberCode') !== 'mubai') return false
      const res = await api.createCode({
        count: 1
      })
      if (res) {
        this.$refs.formBuild.setForm({
          code: res[0]
        })
      }
    },
    async getShiftList() {
      const res = await shiftApi.getShiftList(this.defaultSearch)
      if (res) {
        this.formList.addRepair[4].children[0].options = res
        this.formOptions.shiftOptions = res
      }
    },
    equipmentClassChange(val) {
      const current = this.formOptions.equipmentClassList.find(item => item.id === val)
      this.$refs.formBuild.setForm({
        equipmentClassType: current.type
      })
    },
    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData(true)
    },
    // 更多操作
    more(row, command) {
      const functions = {
        switchStatus: () => { this.switchStatus(row, this.params.radio) },
        modify: () => { this.modify(row, command) },
        copy: () => { this.modify(row, command) },
        print: () => { this.printLabel(row) },
        del: () => { this.del(row) }
      }
      functions[command]()
    },
    printLabel(row) {
      this.jumpDetailNewTab(row)
      // this.$refs.printCode.print(row.code)
    },
    jumpDetailNewTab(row) {
      this.moreTabSetSessionStorage()
      const path = { name: 'basePrint', query: { id: row.id }}
      const routeUrl = this.$router.resolve(path)
      window.open(routeUrl.href, '_blank')
    },
    moreTabSetSessionStorage() {
      if (!sessionStorage.length) {
        localStorage.setItem('getSessionStorage', Date.now())
      }
      window.addEventListener('storage', (event) => {
        if (event.key === 'getSessionStorage') {
          localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage))
          localStorage.removeItem('sessionStorage')
        } else {
          if (event.key === 'sessionStorage' && !sessionStorage.length) {
            const data = JSON.parse(event.newValue).value
            for (const key in data) {
              sessionStorage.setItem(key, data[key])
            }
          }
        }
      })
    },
    // 查看详情
    viewDetail(row) {
      this.$router.push({
        name: 'equipmentAccountDetail',
        params: { id: row.id }
      })
    },
    // 新建维保任务
    addEquipmentRepair(row) {
      this.formData = {
        equipmentId: row.id,
        equipmentName: row.name,
        equipmentCode: row.code
      }
      this.currentType = 'addRepair'
      this.visible = true
    },
    submit(callback) {
      if (this.currentType !== 'addRepair') {
        this.submitForm(callback)
      }
      if (this.currentType === 'addRepair') {
        this.submitRepairForm(callback)
      }
    },
    // 新建维保任务
    submitRepairForm(callback) {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const currentUser = this.formOptions.userOptions.find(item => item.id === form.submitWorkerId)
          const currentShift = this.formOptions.shiftOptions.find(item => item.id === form.submitShiftId)
          const res = await api.addRepairRecord({
            ...form,
            exceptFinishTime: dayjs(form.exceptFinishTime).format('YYYY-MM-DD HH:mm:ss'),
            submitWorkerName: currentUser.name,
            submitShiftName: currentShift.name
          })
          if (res) {
            this.$message.success(this.getSuccessMessage())
            this.visible = false
            this.$refs.mTable.setTableData()
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    createSetData(raw) {
      const equipmentClass = this.formOptions.equipmentClassList.find(item => item.id === raw.equipmentClassId)
      const oName = this.formOptions.organizationList.find((item) => item.id === (raw.organizationId || []).at(-1))
      const uName = this.formOptions.userOptions.find((item) => item.id === raw.equipmentOwnerId)
      return {
        code: raw.code,
        name: raw.name,
        equipmentClassType: raw.equipmentClassType,
        equipmentClassId: equipmentClass.id,
        equipmentClassName: equipmentClass.name,
        isEnabled: raw.isEnabled,
        buyTime: raw.buyTime && dayjs(raw.buyTime).format('YYYY-MM-DD'),
        drawing: raw.drawing,
        stationId: raw.stationId || 0,
        customerId: raw.customerId || "",
        fixedCode: raw.fixedCode,
        fixedName: raw.fixedName,
        fixedPosition: raw.fixedPosition,
        unitType: raw.unitType,
        manufacturingNumber: raw.manufacturingNumber,
        manufacturer: raw.manufacturer,
        // organizationId: raw.organizationId,
        organizationName: (oName || {}).name,
        organizationId: (raw.organizationId || []).at(-1),
        organizationIdList: (raw.organizationId || []).join('^'),
        equipmentOwnerId: raw.equipmentOwnerId,
        equipmentOwnerName: (uName || {}).name,
        remark: raw.remark || ''
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}
.selectType {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: #F5F7FA;
  }
  .name-tit {
    width: 190px;
    display: block;
  }
}
.nodata {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  color: #999;
  height: 34px;
  line-height: 34px;
  text-align: center;
}
.select-add {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  background: #EBEEFA;
  // margin-bottom: -6px;
  cursor: pointer;
  .icons {
    width: 16px;
    height: 16px;
    color: #607FFF;
  }
  .tit {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 360;
    color: #393D60;
    margin-left: 5px;
  }
}
.com-ipts {
  width: 100%;
  background-color: #FFFFFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 32px;
  line-height: 32px;
  outline: none;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icons {
    text-align: right;
    display: inline-block;
    color: #C0C4CC;
    font-size: 14px;
  }
}
.content-container {
  max-height: 300px;
  overflow: auto;
}
.scrap-wrap {
  width: 100%;

  .bottom {
    padding: 10px;
  }
  ::v-deep {
    .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    }
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 150px;
    display: block;
  }
  .drawing-img {
    width: 50px;
    height: 48px;
    display: block;
    cursor: pointer;
    color: #fff;
  }

}
</style>
